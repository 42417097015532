/* 
body {
  background-color: #1f2c58;
} */

.Login {
    padding: 60px 60px;
    text-align: center;
    align-content: center;
      }

  .loginform {
    padding-top: 75px; 
    margin: 0 auto;
    width: 400px;
  }

.cookielink {
  text-decoration: underline;
  font-size: 100%;
  color: lightgrey;
  font-weight: bold;
}
.cookielink:hover {
  text-decoration: underline;
  font-size: 100%;
  color: white;
  font-weight: bold;
}

.cookiemessage {
  font-size: 125%;
}

.cookiebutton {
  background-color: #009b4b !important; 
  color: white !important;
  font-weight: bold !important;
}
