.Home .lander {
    padding: 80px 0;
    text-align: center;
  }
  
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .Home .lander p {
    color: #999;
  }

 .logobackground {
    position: absolute;
    background-image: url('../images/'+env(REACT_APP_BRANDING)+'-logo-large.png');
    z-index: -1;    
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;    
    height: calc(100vh - 100px); 
    width: 100%;
    background-repeat: no-repeat; 
    background-position: center;
    opacity: 0.25;
  }
  .applicationmessages {
    position: relative;
    z-index: 1;
    height: calc(100vh - 105px); 
  }