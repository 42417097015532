.topmenu {padding-top: 20px !important; width: 100px}
.poweredby {margin: auto; width: 25%; text-align: center}
  /* .form {
    width: 50%;
} */
/* .se.fileimport {width: 1800px; padding: 8px;} */ */
/* .segment.importfile {width: 500px; padding-top: 8px; padding-bottom: 8px;} */
/* .segment.importlist {width: 1000px; padding: 8px;} */

/* .segment.rawdata {padding: 8px; overflow: auto; height: 400px} */
.ignorethis {width: 100vw; padding: 8px; overflow: auto; height: calc(100vh - 625px)}
.rawdata {width: 100vw; padding: 8px; overflow: auto !important; height: 275px}
.errorview {width: 100vw; padding: 8px; overflow: auto; height: calc(100vh - 750px)}
.structureddata {padding: 8px; overflow-y: auto; overflow-x: hidden; height: calc(100vh - 465px)}
.input {width: 150px; padding: 0px 0px;}

/* .select.rawdata {width: 150px; padding: 0em 0em; margin: 0px;} */

.uploads {width: 100vw; height: 260px; overflow: auto; margin: 0px !important; padding: 0px !important}
.filepond--root {font-size: 16px;}


.disabled {opacity: 0.75 !important} 
.menubar {margin: 0px 0px 0px 0px !important; padding: 0px}
/* .item {padding: 6px !important} */


.list {
  padding-left: 0px;
  margin-left: 0px;
}
.input.disabled {opacity: 0.9 !important} 
.disabled {opacity: 0.9 !important} 
.jsonedit{height: 110%; overflow: hidden;}
.jsonobjects{height: calc(100vh - 520px); overflow: auto;}
.jsonfields{padding-top: 5px; height: calc(100vh - 520px); overflow: auto;}
.grid {height: 100%; overflow: hidden;}
  /* .form {
    width: 50%;
} */
/* .se.fileimport {width: 1800px; padding: 8px;} */ */
/* .segment.importfile {width: 500px; padding-top: 8px; padding-bottom: 8px;} */
/* .segment.importlist {width: 1000px; padding: 8px;} */

/* .segment.rawdata {padding: 8px; overflow: auto; height: 400px} */

.authorisations {width: 100vw; height: calc(100vh - 220px); overflow: auto;}

.menubar {margin: 0px 0px 0px 0px !important; padding: 0px}
/* .item {padding: 6px !important} */
.mpxnlist {width: 100%; max-height: 250px; overflow: auto;  margin-top: 10px; margin-bottom: 10px;}
.totalboxes {margin-top: 10px; margin-bottom: 10px;}

.input.pageinput {margin: 0px !important; padding: 0px !important; float: right; width: 75px;}

.pagination.menu .active.item {width:inherit !important; color: inherit !important; background-color: #E0E0E0 !important;}
.pagination.menu .item:hover {width:inherit !important; color: inherit !important; background-color: #F2F2F2 !important;}

/* 
body {
  background-color: #1f2c58;
} */

.Login {
    padding: 60px 60px;
    text-align: center;
    -webkit-align-content: center;
            align-content: center;
      }

  .loginform {
    padding-top: 75px; 
    margin: 0 auto;
    width: 400px;
  }

.cookielink {
  text-decoration: underline;
  font-size: 100%;
  color: lightgrey;
  font-weight: bold;
}
.cookielink:hover {
  text-decoration: underline;
  font-size: 100%;
  color: white;
  font-weight: bold;
}

.cookiemessage {
  font-size: 125%;
}

.cookiebutton {
  background-color: #009b4b !important; 
  color: white !important;
  font-weight: bold !important;
}

.Home .lander {
    padding: 80px 0;
    text-align: center;
  }
  
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .Home .lander p {
    color: #999;
  }

 .logobackground {
    position: absolute;
    background-image: url('../images/'+env(REACT_APP_BRANDING)+'-logo-large.png');
    z-index: -1;    
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;    
    height: calc(100vh - 100px); 
    width: 100%;
    background-repeat: no-repeat; 
    background-position: center;
    opacity: 0.25;
  }
  .applicationmessages {
    position: relative;
    z-index: 1;
    height: calc(100vh - 105px); 
  }
.message  {margin: auto;
    }
.table {margin-right: auto; margin-top: 0px; padding-top: 0px; }
.segment.buttonbar {height: 30px; }
.segment.datagrid {height: calc(100vh - 235px); overflow: auto; }

.ui.menu .header.item {color: white !important;}
.leftmenu .header.item {color: white !important; padding-left: 5px !important}
.leftmenu .item {color: white !important; padding-left: 25px !important}
.leftmenu.menu {max-height: calc(100vh - 125px); width: 100%; overflow: auto; min-height: 650px;}
.ui.menu .header.item.leftMenuHeader {color: white !important; min-height: 50px;}

.ui.table td {
    padding: 2px;
    padding-right: 5px;
}

.infotable.ui.table {
    border: none;
}

