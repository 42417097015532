  /* .form {
    width: 50%;
} */
/* .se.fileimport {width: 1800px; padding: 8px;} */ */
/* .segment.importfile {width: 500px; padding-top: 8px; padding-bottom: 8px;} */
/* .segment.importlist {width: 1000px; padding: 8px;} */

/* .segment.rawdata {padding: 8px; overflow: auto; height: 400px} */
.ignorethis {width: 100vw; padding: 8px; overflow: auto; height: calc(100vh - 625px)}
.rawdata {width: 100vw; padding: 8px; overflow: auto !important; height: 275px}
.errorview {width: 100vw; padding: 8px; overflow: auto; height: calc(100vh - 750px)}
.structureddata {padding: 8px; overflow-y: auto; overflow-x: hidden; height: calc(100vh - 465px)}
.input {width: 150px; padding: 0px 0px;}

/* .select.rawdata {width: 150px; padding: 0em 0em; margin: 0px;} */

.uploads {width: 100vw; height: 260px; overflow: auto; margin: 0px !important; padding: 0px !important}
.filepond--root {font-size: 16px;}


.disabled {opacity: 0.75 !important} 
.menubar {margin: 0px 0px 0px 0px !important; padding: 0px}
/* .item {padding: 6px !important} */

